<template>
    <div class="w-full mx-auto">
        <div class="bg-white text-gray-900 shadow-md rounded px-8 pt-6 pb-8 mb-4 overflow-y-scroll" style="height: 80vh">
            <div v-for="(question, key) in questions" v-bind:key="key" class="mb-4">
                <template v-if="question.help">
                    <p class="font-bold" v-html="question.help"></p>
                </template>
                <template v-if="question.type === 'likert-5'">
                    <strong>{{ question.question }}</strong><br>

                    <label>
                        <input v-model="analytics.answers[key]" type="radio" value="likert-1"> Trifft voll und ganz zu<br>
                    </label>
                    <label>
                        <input v-model="analytics.answers[key]" type="radio" value="likert-2"> Trifft eher zu<br>
                    </label>
                    <label>
                        <input v-model="analytics.answers[key]" type="radio" value="likert-3"> Teils-teils<br>
                    </label>
                    <label>
                        <input v-model="analytics.answers[key]" type="radio" value="likert-4"> Trifft eher nicht zu<br>
                    </label>
                    <label>
                        <input v-model="analytics.answers[key]" type="radio" value="likert-5"> Trifft überhaupt nicht zu<br>
                    </label>
                </template>
                <template v-else-if="question.type === 'boolean'">
                    <strong>{{ question.question }}</strong><br>

                    <label>
                        <input v-model="analytics.answers[key]" type="radio" value="yes"> Ja<br>
                    </label>
                    <label>
                        <input v-model="analytics.answers[key]" type="radio" value="no"> Nein<br>
                    </label>
                </template>
                <template v-else-if="question.type === 'choice'">
                    <table class="w-full mb-8">
                        <tbody>
                            <tr>
                                <td v-for="(choice, keyChoice) in question.choices" v-bind:key="keyChoice" class="w-1/2">
                                    <label>
                                        <input v-model="analytics.answers[key]" type="radio" v-bind:name="key" v-bind:value="keyChoice" required> {{ choice }}
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </template>
                <template v-else-if="question.type === 'likert-5-table'">
                    <table class="w-full mb-8">
                        <thead>
                            <tr>
                                <th></th>
                                <th>trifft gar nicht zu</th>
                                <th>trifft wenig zu</th>
                                <th>teils/teils</th>
                                <th>trifft überwiegend zu</th>
                                <th>trifft völlig zu</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(choice, keyChoice) in question.questions" v-bind:key="keyChoice">
                                <td class="w-1/2">{{ choice }}</td>
                                <td><input v-model="analytics.answers[key][keyChoice]" type="radio" v-bind:name="'question-' + key + '-' + keyChoice" v-bind:value="1" required></td>
                                <td><input v-model="analytics.answers[key][keyChoice]" type="radio" v-bind:name="'question-' + key + '-' + keyChoice" v-bind:value="2" required></td>
                                <td><input v-model="analytics.answers[key][keyChoice]" type="radio" v-bind:name="'question-' + key + '-' + keyChoice" v-bind:value="3" required></td>
                                <td><input v-model="analytics.answers[key][keyChoice]" type="radio" v-bind:name="'question-' + key + '-' + keyChoice" v-bind:value="4" required></td>
                                <td><input v-model="analytics.answers[key][keyChoice]" type="radio" v-bind:name="'question-' + key + '-' + keyChoice" v-bind:value="5" required></td>
                            </tr>
                        </tbody>
                    </table>
                </template>
                <template v-else-if="question.type === 'likert-6-table'">
                    <table class="w-full mb-8">
                        <thead>
                            <tr>
                                <th></th>
                                <th>stimme überhaupt nicht zu</th>
                                <th>stimme kaum zu</th>
                                <th>stimme wenig zu</th>
                                <th>stimme leicht zu</th>
                                <th>stimme eher zu</th>
                                <th>stimme voll zu</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(choice, keyChoice) in question.questions" v-bind:key="keyChoice">
                                <td class="w-1/2">{{ choice }}</td>
                                <td><input v-model="analytics.answers[key][keyChoice]" type="radio" v-bind:name="'question-' + key + '-' + keyChoice" v-bind:value="1" required></td>
                                <td><input v-model="analytics.answers[key][keyChoice]" type="radio" v-bind:name="'question-' + key + '-' + keyChoice" v-bind:value="2" required></td>
                                <td><input v-model="analytics.answers[key][keyChoice]" type="radio" v-bind:name="'question-' + key + '-' + keyChoice" v-bind:value="3" required></td>
                                <td><input v-model="analytics.answers[key][keyChoice]" type="radio" v-bind:name="'question-' + key + '-' + keyChoice" v-bind:value="4" required></td>
                                <td><input v-model="analytics.answers[key][keyChoice]" type="radio" v-bind:name="'question-' + key + '-' + keyChoice" v-bind:value="5" required></td>
                                <td><input v-model="analytics.answers[key][keyChoice]" type="radio" v-bind:name="'question-' + key + '-' + keyChoice" v-bind:value="6" required></td>
                            </tr>
                        </tbody>
                    </table>
                </template>
                <template v-else-if="question.type === 'likert-7-table'">
                    <table class="w-full mb-8">
                        <thead>
                            <tr>
                                <th></th>
                                <th>trifft überhaupt nicht zu</th>
                                <th>trifft kaum zu</th>
                                <th>trifft wenig zu</th>
                                <th>weder noch</th>
                                <th>trifft leicht zu</th>
                                <th>trifft eher zu</th>
                                <th>trifft ganz genau zu</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(choice, keyChoice) in question.questions" v-bind:key="keyChoice">
                                <td class="w-1/2">{{ choice }}</td>
                                <td><input v-model="analytics.answers[key][keyChoice]" type="radio" v-bind:name="'question-' + key + '-' + keyChoice" v-bind:value="1" required></td>
                                <td><input v-model="analytics.answers[key][keyChoice]" type="radio" v-bind:name="'question-' + key + '-' + keyChoice" v-bind:value="2" required></td>
                                <td><input v-model="analytics.answers[key][keyChoice]" type="radio" v-bind:name="'question-' + key + '-' + keyChoice" v-bind:value="3" required></td>
                                <td><input v-model="analytics.answers[key][keyChoice]" type="radio" v-bind:name="'question-' + key + '-' + keyChoice" v-bind:value="4" required></td>
                                <td><input v-model="analytics.answers[key][keyChoice]" type="radio" v-bind:name="'question-' + key + '-' + keyChoice" v-bind:value="5" required></td>
                                <td><input v-model="analytics.answers[key][keyChoice]" type="radio" v-bind:name="'question-' + key + '-' + keyChoice" v-bind:value="6" required></td>
                                <td><input v-model="analytics.answers[key][keyChoice]" type="radio" v-bind:name="'question-' + key + '-' + keyChoice" v-bind:value="7" required></td>
                            </tr>
                        </tbody>
                    </table>
                </template>
                <template v-else>
                    <div class="bg-red-500 text-white font-bold">
                        Unbekannter Test {{ question.type }}
                    </div>
                </template>
            </div>

            <div class="mb-8">
                <a v-on:click="finished" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline uppercase w-full cursor-pointer" v-text="$t('next')"></a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'questiontest',
    data () {
        return {
            analytics: {
                name: null,
                answers: []
            },
            questions: []
        }
    },
    props: {
        parameters: Object
    },
    mounted () {
        for (let i = 0; i < this.parameters.questions.length; i++) {
            this.analytics.answers.push({})
        }

        this.questions = this.parameters.questions

        this.analytics.name = this.parameters.name

        console.debug('Starting questions')
    },
    methods: {
        finished () {
            this.$emit('finished', this.analytics)
        }
    }
}
</script>
