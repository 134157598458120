<template>
    <div class="h-full w-full">
        <template v-if="input">
            <p class="font-bold" v-text="$t('test.reminder.input')"></p>

            <div class="flex flex-wrap -mx-4">
                <div v-for="key in inputs" v-bind:key="key" class="w-1/5 text-2xl p-4">
                    <input type="text" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" v-bind:id="'input-' + key" autocomplete="off">
                </div>
            </div>

            <div class="block mt-8">
                <a @click="finished()" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline uppercase w-full cursor-pointer" v-text="$t('next')"></a>
            </div>
        </template>
        <div v-else class="flex justify-center flex-wrap">
            <div v-for="word in parameters.words" v-bind:key="word" class="w-1/5 text-2xl m-4 py-2 bg-gray-100 text-gray-900 text-center">
                {{ word }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReminderTest',
    data () {
        return {
            input: false,
            inputs: [],
            analytics: {
                words: [],
                inputs: []
            }
        }
    },
    props: {
        parameters: Object
    },
    mounted () {
        this.analytics.words = this.parameters.words

        for (let i = 0; i < this.parameters.words.length; i++) {
            this.inputs.push(i)
        }

        this.startRemember()

        console.debug('Starting reminder test')
    },
    methods: {
        startRemember () {
            setTimeout(this.startInput, this.parameters.timeRemember)
        },
        startInput () {
            this.input = true

            setTimeout(this.finished, this.parameters.timeInput)
        },
        finished () {
            for (let i = 0; i < this.inputs.length; i++) {
                this.analytics.inputs.push(document.querySelector('#input-' + i).value)
            }

            this.$emit('finished', this.analytics)
        }
    }
}
</script>
