<template>
    <div class="relative h-full w-full h-full flex flex-col justify-center">
        <a v-on:click="buttonPress(true)"
           class="absolute left-0 bg-blue-500 hover:bg-blue-700 text-white text-xl uppercase font-bold p-4 rounded-full w-16 h-16 text-center cursor-pointer"
           style="top: 50%;">x</a>
        <a v-on:click="buttonPress(false)"
           class="absolute right-0 bg-blue-500 hover:bg-blue-700 text-white text-xl uppercase font-bold p-4 rounded-full w-16 h-16 text-center cursor-pointer"
           style="top: 50%;">n</a>

        <template v-if="pause">
        </template>
        <template v-else>
            <template v-if="!parameters.type || parameters.type === 1">
                <div class="flex flex-col items-center">
                    <div class="test-circle" v-bind:class="currentCircleTop"></div>

                    <div class="test-circle" v-bind:class="currentCircleCenter"></div>

                    <div class="test-circle" v-bind:class="currentCircleBottom"></div>
                </div>
            </template>
            <template v-else-if="parameters.type === 2">
                <div class="flex justify-center">
                    <div class="test-circle" v-bind:class="currentCircleTop"></div>
                </div>

                <div class="flex justify-center">
                    <div class="test-circle" v-bind:class="currentCircleTop"></div>
                    <div class="test-circle" v-bind:class="currentCircleCenter"></div>
                    <div class="test-circle" v-bind:class="currentCircleBottom"></div>
                </div>

                <div class="flex justify-center">
                    <div class="test-circle" v-bind:class="currentCircleBottom"></div>
                </div>
            </template>
            <template v-else-if="parameters.type === 3">
                <div class="flex justify-center">
                    <div class="test-circle" v-bind:class="currentCircleCenter"></div>
                    <div class="test-circle" v-bind:class="currentCircleTop"></div>
                    <div class="test-circle" v-bind:class="currentCircleBottom"></div>
                </div>

                <div class="flex justify-center">
                    <div class="test-circle" v-bind:class="currentCircleTop"></div>
                    <div class="test-circle" v-bind:class="currentCircleCenter"></div>
                    <div class="test-circle" v-bind:class="currentCircleBottom"></div>
                </div>

                <div class="flex justify-center">
                    <div class="test-circle" v-bind:class="currentCircleCenter"></div>
                    <div class="test-circle" v-bind:class="currentCircleBottom"></div>
                    <div class="test-circle" v-bind:class="currentCircleTop"></div>
                </div>
            </template>
        </template>
    </div>
</template>

<style scoped>
    .test-circle {
        @apply h-20 w-20 mx-4 my-4 rounded-full border border-gray-100 bg-white;
    }

    .test-circle-l {
        background-image: repeating-linear-gradient(
            45deg,
            transparent,
            black 5px
        );
    }

    .test-circle-r {
        background-image: repeating-linear-gradient(
            -45deg,
            transparent,
            black 5px
        );
    }

    .test-circle-v {
        background-image: repeating-linear-gradient(
            90deg,
            transparent,
            black 5px
        );
    }

    .test-circle-h {
        background-image: repeating-linear-gradient(
            0deg,
            transparent,
            black 5px
        );
    }
</style>

<script>
import moment from 'moment'
import { Howl } from 'howler'

export default {
    name: 'CircleTest',
    data () {
        return {
            series: {
                top: [],
                center: [],
                bottom: []
            },
            index: -1,
            pause: true,
            analytics: {
                series: [],
                clicks: []
            },
            timer: 0,
            mc: null,
            active: false,
            confirmationSound: null
        }
    },
    props: {
        parameters: Object
    },
    mounted () {
        this.timer = moment()
        this.confirmationSound = new Howl({
            src: ['sounds/click.mp3', 'sounds/click.wav']
        })

        const DIRECTIONS = ['l', 'r', 'h', 'v']

        for (let i = 0; i < this.parameters.count; i++) {
            this.series.top.push(DIRECTIONS[Math.floor(Math.random() * DIRECTIONS.length)])
            this.series.center.push(DIRECTIONS[Math.floor(Math.random() * DIRECTIONS.length)])
            this.series.bottom.push(DIRECTIONS[Math.floor(Math.random() * DIRECTIONS.length)])
        }

        this.analytics.series = this.series

        this.startPause()

        console.debug('Starting circle test')
    },
    created () {
        window.addEventListener('keypress', this.keyPressed)
    },
    destroyed () {
        window.removeEventListener('keypress', this.keyPressed)
    },
    methods: {
        endPause () {
            const that = this

            if (this.index < this.parameters.count) {
                this.pause = false
                this.timer = moment()

                setTimeout(function () {
                    that.active = true
                }, 0)

                setTimeout(this.startPause, this.parameters.timeShow)
            } else {
                this.$emit('finished', this.analytics)
            }
        },
        startPause () {
            this.pause = true
            this.active = false
            this.index++

            setTimeout(this.endPause, this.parameters.timeShow)
        },
        keyPressed (e) {
            /**
             * @type {KeyboardEvent} e
             */
            const key = e.key.toLowerCase()

            if (key === 'x') {
                this.buttonPress(true)
            } else if (key === 'n') {
                this.buttonPress(false)
            } else {
                console.warn('Unknown key', key)
            }
        },
        buttonPress (left) {
            console.debug('Button pressed', left ? 'left' : 'right')

            this.confirmationSound.play()

            const diff = moment.duration(moment().diff(this.timer))

            let success = false
            if (this.series.center[this.index] === 'r' && left === false) {
                success = true
            }
            if (this.series.center[this.index] === 'l' && left === true) {
                success = true
            }

            this.analytics.clicks.push({
                time: moment().toISOString(),
                timeRelative: diff.asMilliseconds(),
                success: success,
                index: this.index,
                pause: this.pause
            })
        }
    },
    computed: {
        currentCircleTop () {
            return {
                'test-circle-l': this.series.top[this.index] === 'l',
                'test-circle-r': this.series.top[this.index] === 'r',
                'test-circle-h': this.series.top[this.index] === 'h',
                'test-circle-v': this.series.top[this.index] === 'v'
            }
        },
        currentCircleCenter () {
            return {
                'test-circle-l': this.series.center[this.index] === 'l',
                'test-circle-r': this.series.center[this.index] === 'r',
                'test-circle-h': this.series.center[this.index] === 'h',
                'test-circle-v': this.series.center[this.index] === 'v'
            }
        },
        currentCircleBottom () {
            return {
                'test-circle-l': this.series.bottom[this.index] === 'l',
                'test-circle-r': this.series.bottom[this.index] === 'r',
                'test-circle-h': this.series.bottom[this.index] === 'h',
                'test-circle-v': this.series.bottom[this.index] === 'v'
            }
        }
    }
}
</script>
