<template>
    <div class="relative w-full h-full">
        <div v-if="pause" class="w-full h-full flex flex-col justify-center">
            <p class="block text-4xl font-bold text-center">
                <template v-if="test">
                    <span v-text="$t('test.attention.test_round')"></span><br><br>

                    <a @click="initTest" class="text-2xl inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 py-2 rounded text-center cursor-pointer" v-text="$t('test.start')"></a>
                </template>
                <template v-else>
                    <span v-text="$t('test.attention.live_round')"></span><br>

                    <a @click="initLive" class="text-2xl inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 py-2 rounded text-center cursor-pointer" v-text="$t('test.start')">Start</a>
                </template>
            </p>
        </div>
        <template v-else>
            <div class="absolute overflow-x-hidden inline-block whitespace-no-wrap" v-bind:style="{ 'transform': translateX, 'transition-duration': transitionDuration, 'right': (-1 * width) + 'px' }"
                 style="top: 40%; transition-property: all; transition-timing-function: linear;">
                <div v-for="item in series" v-bind:key="item.key"
                     class="cursor-pointer m-2 border-2 border-transparent inline-block"
                     style="width: 150px; height: 150px;"
                     v-bind:class="{ 'border-green-500': item.clickedCorrect === true, 'border-red-500': item.clickedCorrect === false }"
                     v-on:click="clickSymbol(item)">
                    <div class="relative w-100" style="padding-top: 100%">
                        <div class="absolute border border-gray-100 w-1/2"
                             v-bind:class="{ 'rounded-full': item.kind === 'circle' }"
                             style="height: 50%; top: 25%; left: 25%">
                        </div>
                        <div class="absolute bg-gray-100 h-full" style="left: 50%; top: 0; width: 1px;">
                        </div>
                        <div class="absolute bg-gray-100 w-full" style="left: 0; top: 50%; height: 1px;">
                        </div>

                        <div v-if="item.tl.circle" class="absolute bg-gray-100 w-3 h-3 rounded-full"
                             v-bind:style="{ top: item.tl.inner ? '35%' : '10%', left: item.tl.inner ? '35%' : '10%' }">
                        </div>
                        <div v-if="item.tr.circle" class="absolute bg-gray-100 w-3 h-3 rounded-full"
                             v-bind:style="{ top: item.tr.inner ? '35%' : '10%', right: item.tr.inner ? '35%' : '10%' }">
                        </div>
                        <div v-if="item.bl.circle" class="absolute bg-gray-100 w-3 h-3 rounded-full"
                             v-bind:style="{ bottom: item.bl.inner ? '35%' : '10%', left: item.bl.inner ? '35%' : '10%' }">
                        </div>
                        <div v-if="item.br.circle" class="absolute bg-gray-100 w-3 h-3 rounded-full"
                             v-bind:style="{ bottom: item.br.inner ? '35%' : '10%', right: item.br.inner ? '35%' : '10%' }">
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import moment from 'moment'
import { Howl } from 'howler'

export default {
    name: 'AttentionTest',
    data () {
        return {
            series: [],
            pause: true,
            test: true,
            analytics: {
                series: [],
                clicks: [],
            },
            timer: 0,
            mc: null,
            active: false,
            clickSound: null,
            testInput: false,
            width: '150px',
            transitionDuration: '10s',
            translateX: 'none',
        }
    },
    props: {
        parameters: Object,
    },
    mounted () {
        this.timer = moment()
        this.clickSound = new Howl({
            src: [
                'sounds/click.mp3',
                'sounds/click.wav',
            ],
        })
    },
    methods: {
        initTest () {
            const that = this
            const symbolCount = Math.round(this.parameters.symbols / 5)

            for (let i = 0; i < symbolCount; i++) {
                const points = {
                    tl: false,
                    tr: false,
                    bl: false,
                    br: false,
                }

                const POSITIONS = ['tl', 'tr', 'bl', 'br']

                const numberOfPoints = (Math.random() > 0.5) ? 2 : 3

                while (this.numberOfTruePoints(points) < numberOfPoints) {
                    points[POSITIONS[Math.floor(Math.random() * POSITIONS.length)]] = true
                }

                this.series.push({
                    tl: {
                        circle: points.tl,
                        inner: Math.random() > 0.5,
                    },
                    tr: {
                        circle: points.tr,
                        inner: Math.random() > 0.5,
                    },
                    bl: {
                        circle: points.bl,
                        inner: Math.random() > 0.5,
                    },
                    br: {
                        circle: points.br,
                        inner: Math.random() > 0.5,
                    },
                    index: i,
                    clicked: false,
                    clickedCorrect: null,
                    kind: Math.random() > 0.5 ? 'circle' : 'square',
                    numberOfPoints: numberOfPoints,
                })
            }

            setTimeout(function () {
                console.debug('Finished test test')

                that.test = false
                that.pause = true
                that.translateX = 'none'
            }, this.parameters.duration / 5)

            this.pause = false

            this.width = (symbolCount * (150 + 16))
            this.translateX = 'translateX(' + (this.width * -1) + 'px)'
            this.transitionDuration = Math.round(this.parameters.duration / 5 / 1000) + 's'

            console.debug('Starting attention test test')
        },
        initLive () {
            const that = this

            // Clear test series
            this.series = []

            for (let i = 0; i < this.parameters.symbols; i++) {
                const points = {
                    tl: false,
                    tr: false,
                    bl: false,
                    br: false,
                }

                const POSITIONS = ['tl', 'tr', 'bl', 'br']

                const numberOfPoints = (Math.random() > 0.5) ? 2 : 3

                while (this.numberOfTruePoints(points) < numberOfPoints) {
                    points[POSITIONS[Math.floor(Math.random() * POSITIONS.length)]] = true
                }

                this.series.push({
                    tl: {
                        circle: points.tl,
                        inner: Math.random() > 0.5,
                    },
                    tr: {
                        circle: points.tr,
                        inner: Math.random() > 0.5,
                    },
                    bl: {
                        circle: points.bl,
                        inner: Math.random() > 0.5,
                    },
                    br: {
                        circle: points.br,
                        inner: Math.random() > 0.5,
                    },
                    index: i,
                    clicked: false,
                    clickedCorrect: null,
                    kind: Math.random() > 0.5 ? 'circle' : 'square',
                    numberOfPoints: numberOfPoints,
                })
            }

            this.analytics.series = this.series

            setTimeout(function () {
                console.debug('Finished live test')

                return that.$emit('finished', that.analytics)
            }, this.parameters.duration)

            this.startPosition = Math.max(
                document.body.scrollWidth,
                document.documentElement.scrollWidth,
                document.body.offsetWidth,
                document.documentElement.offsetWidth,
                document.documentElement.clientWidth,
            ) / 2

            this.left = this.startPosition

            this.pause = false

            this.width = (this.parameters.symbols * (150 + 16))
            this.translateX = 'translateX(' + (this.width * -1) + 'px)'
            this.transitionDuration = Math.round(this.parameters.duration / 1000) + 's'

            console.debug('Starting attention live test')
        },
        numberOfTruePoints (pointsList) {
            let points = 0

            if (pointsList.tl) {
                points++
            }
            if (pointsList.tr) {
                points++
            }
            if (pointsList.bl) {
                points++
            }
            if (pointsList.br) {
                points++
            }

            return points
        },
        clickSymbol (item) {
            if (item.clicked === false) {
                this.clickSound.play()
            }

            item.clicked = true

            const diff = moment.duration(moment().diff(this.timer))
            let pointsInner = 0
            let pointsOuter = 0
            let success = false

            if (item.kind === 'circle') {
                if (item.tl.circle && item.tl.inner) {
                    pointsInner++
                }
                if (item.tr.circle && item.tr.inner) {
                    pointsInner++
                }
                if (item.bl.circle && item.bl.inner) {
                    pointsInner++
                }
                if (item.br.circle && item.br.inner) {
                    pointsInner++
                }

                if (item.tl.circle && !item.tl.inner) {
                    pointsOuter++
                }
                if (item.tr.circle && !item.tr.inner) {
                    pointsOuter++
                }
                if (item.bl.circle && !item.bl.inner) {
                    pointsOuter++
                }
                if (item.br.circle && !item.br.inner) {
                    pointsOuter++
                }

                if ((this.parameters.type === 1 && pointsInner === 2 && pointsOuter === 0) ||
                    (this.parameters.type === 2 && pointsInner === 2 && pointsOuter === 0) ||
                    (this.parameters.type === 2 && pointsInner === 1 && pointsOuter === 2) ||
                    (this.parameters.type === 3 && pointsInner === 2 && pointsOuter === 0) ||
                    (this.parameters.type === 3 && pointsInner === 1 && pointsOuter === 2)) {
                    success = true
                }
            } else if (item.kind === 'square') {
                if (item.tl.circle && item.tl.inner) {
                    pointsInner++
                }
                if (item.tr.circle && item.tr.inner) {
                    pointsInner++
                }
                if (item.bl.circle && item.bl.inner) {
                    pointsInner++
                }
                if (item.br.circle && item.br.inner) {
                    pointsInner++
                }

                if (item.tl.circle && !item.tl.inner) {
                    pointsOuter++
                }
                if (item.tr.circle && !item.tr.inner) {
                    pointsOuter++
                }
                if (item.bl.circle && !item.bl.inner) {
                    pointsOuter++
                }
                if (item.br.circle && !item.br.inner) {
                    pointsOuter++
                }

                if ((this.parameters.type === 1 && pointsOuter === 3 && pointsInner === 0) ||
                    (this.parameters.type === 2 && pointsOuter === 3 && pointsInner === 0) ||
                    (this.parameters.type === 3 && pointsOuter === 3 && pointsInner === 0) ||
                    (this.parameters.type === 3 && pointsOuter === 1 && pointsInner === 3)) {
                    success = true
                }
            }

            item.clickedCorrect = success

            console.debug('Check result', success)

            if (!this.test) {
                this.analytics.clicks.push({
                    time: moment().toISOString(),
                    timeRelative: diff.asMilliseconds(),
                    success: success,
                    index: this.index,
                    item: item,
                })
            }
        },
    },
}
</script>
