<template>
    <div class="h-full w-full">
        <p class="font-bold" v-text="$t('attention.reminder.repeat')"></p>

        <div class="flex flex-wrap -mx-4">
            <div v-for="key in inputs" v-bind:key="key" class="w-1/5 text-2xl p-4">
                <input type="text" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" v-bind:id="'input-' + key">
            </div>
        </div>

        <div class="block mt-8">
            <a @click="finished()" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline uppercase w-full cursor-pointer" v-text="$t('next')"></a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReminderMemoryTest',
    data () {
        return {
            input: false,
            inputs: [],
            analytics: {
                words: [],
                inputs: []
            }
        }
    },
    props: {
        parameters: Object
    },
    mounted () {
        this.analytics.words = this.parameters.words

        for (let i = 0; i < this.parameters.words.length; i++) {
            this.inputs.push(i)
        }

        setTimeout(this.finished, this.parameters.timeInput)

        console.debug('Starting reminder test')
    },
    methods: {
        finished () {
            for (let i = 0; i < this.inputs.length; i++) {
                this.analytics.inputs.push(document.querySelector('#input-' + i).value)
            }

            this.$emit('finished', this.analytics)
        }
    }
}
</script>
