import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
import UAParser from 'ua-parser-js'

import { md5 } from './md5'
import { dbTemplatesLive, dbResultsLive } from './pouchdb'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        settings: {
            namespaced: true,
            state: {
                debugMode: false,
                dbResultsActive: false,
                dbTemplatesActive: false,
                skipRegistration: process.env.VUE_APP_SKIP_REGISTRATION,
            },
            mutations: {
                setDebugMode (state, debugMode) {
                    state.debugMode = debugMode
                },
                setDbResults (state, active) {
                    state.dbResultsActive = active
                },
                setDbTemplates (state, active) {
                    state.dbTemplatesActive = active
                },
            },
            actions: {
                setDebugMode (context, debugMode) {
                    context.commit('setDebugMode', debugMode)
                },
                setDbResults (context, active) {
                    context.commit('setDbResults', active)
                },
                setDbTemplates (context, active) {
                    context.commit('setDbTemplates', active)
                },
            },
        },
        results: {
            namespaced: true,
            state: {
                results: [],
            },
            mutations: {
                set (state, args) {
                    state.results[args.hash] = args.attributes
                },
            },
            actions: {
                createOrLoad (context, payload) {
                    const user = payload.user
                    const registrationType = payload.registrationType

                    let hash = null
                    let attributes = null

                    if (registrationType === 0) {
                        console.debug('Registration type: 0')

                        hash = md5(user.surname.toUpperCase() + '-' + user.lastname.toUpperCase() + '-' + moment(user.birthday).format('DD.MM.YYYY'))
                        attributes = {
                            _id: hash,
                            birthday: moment(user.birthday).format('DD.MM.YYYY'),
                            name: user.surname + ' ' + user.lastname,
                            sex: user.sex,
                            analytics: [],
                        }
                    } else {
                        console.debug('Registration type: 1')

                        hash = moment(user.birthday).format('DD.MM.YYYY') + '-' + user.birthplace.toUpperCase() + '-' + user.name_mother.toUpperCase() + '-' + user.name_father.toUpperCase()
                        attributes = {
                            _id: hash,
                            birthday: moment(user.birthday).format('DD.MM.YYYY'),
                            analytics: [],
                        }
                    }

                    console.debug('Hash for current user', hash)

                    return new Promise(function (resolve, reject) {
                        dbResultsLive.put(attributes)
                            .then(function (response) {
                                if (response.ok) {
                                    console.debug('Result for user added')

                                    context.commit('set', {
                                        hash: hash,
                                        attributes: attributes,
                                    })

                                    return resolve(hash)
                                }

                                console.error('Could not create user result', attributes)

                                return reject(new Error('Could not create user result!'))
                            })
                            .catch(function (err) {
                                if (err.status === 409 && err.name === 'conflict') {
                                    console.debug('User already added', hash)

                                    dbResultsLive.get(hash)
                                        .then(function (doc) {
                                            console.debug('Loaded user from database')

                                            context.commit('set', {
                                                hash: hash,
                                                attributes: doc,
                                            })

                                            return resolve(hash)
                                        })
                                        .catch(reject)
                                } else {
                                    console.error(err)

                                    return reject(err)
                                }
                            })
                    })
                },
                append (context, args) {
                    return new Promise(function (resolve, reject) {
                        dbResultsLive.get(args.hash)
                            .then(function (doc) {
                                const analytics = args.analytics
                                const ua = new UAParser()

                                analytics.submitted = Date.now()
                                analytics.browser = ua.getResult()
                                analytics.screen = {
                                    width: window.innerWidth,
                                    height: window.innerHeight,
                                }
                                doc.analytics.push(analytics)

                                dbResultsLive.put(doc)
                                    .then(function () {
                                        context.commit('set', {
                                            hash: args.hash,
                                            attributes: doc,
                                        })

                                        resolve(doc)
                                    })
                                    .catch(reject)
                            })
                            .catch(reject)
                    })
                },
            },
        },
        templates: {
            namespaced: true,
            state: {
                templates: [],
            },
            mutations: {
                setAll (state, docs) {
                    state.templates = docs
                },
                set (state, doc) {
                    let found = false

                    state.templates = state.templates.map(function (template) {
                        if (template.id === doc.id) {
                            found = true
                            template = doc
                        }

                        return template
                    })

                    if (!found) {
                        state.templates.push(doc)
                    }
                },
            },
            actions: {
                loadAll (context) {
                    return new Promise(function (resolve, reject) {
                        dbTemplatesLive.allDocs({
                            include_docs: true,
                        })
                            .then(function (docs) {
                                context.commit('setAll', docs.rows.map(function (row) {
                                    return row.doc
                                }))

                                resolve(docs)
                            })
                            .catch(reject)
                    })
                },
                load (context, id) {
                    return new Promise(function (resolve, reject) {
                        dbTemplatesLive.get(id.toString())
                            .then(function (doc) {
                                context.commit('set', doc)

                                resolve(doc)
                            })
                            .catch(reject)
                    })
                },
            },
        },
    },
    strict: process.env.NODE_ENV !== 'production',
})
