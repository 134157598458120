<template>
    <div class="h-full w-full flex relative -mx-8">
        <div class="w-3/4 h-full mx-4 canvas-wrapper">
            <template v-if="showWarning === false">
                <p v-show="pause" class="text-center">Das nächste Bild erscheint in wenigen Sekunden.</p>

                <canvas v-show="!pause"></canvas>
            </template>
            <div v-else class="alert alert-error">
                WebGL ist nicht auf diesem Gerät verfügbar! Es wird benötgit um 3D Animationen anzuzeigen.
            </div>
        </div>
        <div class="w-1/4 h-full mx-4 flex flex-col">
            <div class="canvas-solution-wrapper mb-2">
                <canvas class="border-4 border-transparent cursor-pointer hover:border-green-100" @click="markSolution(0)"></canvas>
            </div>
            <div class="canvas-solution-wrapper mb-2">
                <canvas class="border-4 border-transparent cursor-pointer hover:border-green-100" @click="markSolution(1)"></canvas>
            </div>
            <div class="canvas-solution-wrapper mb-2">
                <canvas class="border-4 border-transparent cursor-pointer hover:border-green-100" @click="markSolution(2)"></canvas>
            </div>
            <div class="canvas-solution-wrapper mb-2">
                <canvas class="border-4 border-transparent cursor-pointer hover:border-green-100" @click="markSolution(3)"></canvas>
            </div>
            <div class="canvas-solution-wrapper">
                <canvas class="border-4 border-transparent cursor-pointer hover:border-green-100" @click="markSolution(4)"></canvas>
            </div>
        </div>
    </div>
</template>

<style scoped>
.canvas-solution-wrapper {
    height: 20%;
}

canvas {
    @apply block w-full h-full;
}
</style>

<script>
import * as THREE from 'three'
import '../../OrbitControls'
import * as helvetiker from 'three/examples/fonts/helvetiker_regular.typeface.json'

export default {
    name: 'matrixtest',
    data () {
        return {
            analytics: [],
            pause: true,
            timer: null,
            testTimer: null,
            scene: [],
            camera: [],
            renderer: [],
            controls: [],
            objects: [],
            showWarning: false,
        }
    },
    props: {
        parameters: Object,
    },
    mounted () {
        // Check if webgl is available
        const canvas = document.createElement('canvas')
        const isWebGLAvailable = !!(window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')))
        if (!isWebGLAvailable) {
            this.showWarning = true
            return
        }

        // Init scene
        const canvasWrapper = this.$el.querySelector('.canvas-wrapper')

        this.scene.push(new THREE.Scene())
        this.camera.push(new THREE.PerspectiveCamera(60, canvasWrapper.clientWidth / canvasWrapper.clientHeight, 1, 1000))

        this.renderer.push(new THREE.WebGLRenderer({
            antialias: true,
            canvas: canvasWrapper.querySelector('canvas'),
        }))
        this.renderer[0].setSize(canvasWrapper.clientWidth, canvasWrapper.clientHeight)
        this.renderer[0].setClearColor(0xffffff, 1)

        this.controls.push(new THREE.OrbitControls(this.camera[0], this.renderer[0].domElement))

        // Init solutions
        for (let i = 0; i < 5; i++) {
            const canvasSolutionWrapper = this.$el.querySelectorAll('.canvas-solution-wrapper')[i]

            this.scene.push(new THREE.Scene())
            this.camera.push(new THREE.PerspectiveCamera(60, canvasSolutionWrapper.clientWidth / canvasSolutionWrapper.clientHeight, 1, 1000))

            this.renderer.push(new THREE.WebGLRenderer({
                antialias: true,
                canvas: canvasSolutionWrapper.querySelector('canvas'),
            }))
            this.renderer[i + 1].setSize(canvasSolutionWrapper.clientWidth, canvasSolutionWrapper.clientHeight)
            this.renderer[i + 1].setClearColor(0xffffff, 1)

            this.controls.push(new THREE.OrbitControls(this.camera[i + 1], this.renderer[i + 1].domElement))
        }

        // Start test
        this.endPause()

        console.debug('Starting matrix test')
    },
    methods: {
        endPause () {
            const that = this

            this.timer = this.parameters.timeShow / 1000
            that.pause = false

            const white = new THREE.Color('#ffffff')
            const red = new THREE.Color('#c53030')
            const blue = new THREE.Color('#4299e1')
            const green = new THREE.Color('#48bb78')
            const yellow = new THREE.Color('#ecc94b')
            const black = new THREE.Color('#000000')

            const geometry = new THREE.BoxGeometry(10, 10, 10)
            const edges = new THREE.EdgesGeometry(geometry)
            const material = new THREE.LineBasicMaterial({
                color: black,
            })
            const cube = new THREE.LineSegments(edges, material)

            const cube1 = cube.clone()
            cube1.position.x = -40

            const cube2 = cube.clone()
            cube2.position.x = -20

            const cube3 = cube.clone()
            cube3.position.x = 0

            const cube4 = cube.clone()
            cube4.position.x = 20

            const cube5 = cube.clone()
            cube5.position.x = 40

            this.scene[0].add(cube)
            this.scene[0].add(cube1)
            this.scene[0].add(cube2)
            this.scene[0].add(cube3)
            this.scene[0].add(cube4)
            this.scene[0].add(cube5)

            if (this.parameters.type === 1) {
                const coneGeometry = new THREE.ConeGeometry(4, 8, 4)

                const coneColors = [
                    red,
                    blue,
                    yellow,
                    green,
                    red,
                    blue,
                    yellow,
                    green,
                ]

                for (let i = 0; i < coneGeometry.faces.length; i++) {
                    coneGeometry.faces[i].color.set(coneColors[i])
                }

                const coneMaterial = new THREE.MeshBasicMaterial({
                    color: white,
                    vertexColors: THREE.FaceColors,
                })

                const cone = new THREE.Mesh(coneGeometry, coneMaterial)

                const cone1 = cone.clone()
                cone1.position.x = -40
                cone1.rotation.y = THREE.MathUtils.degToRad(-45)

                const cone2 = cone.clone()
                cone2.position.x = -20
                cone2.rotation.y = THREE.MathUtils.degToRad(-135)

                const cone3 = cone.clone()
                cone3.position.x = 0
                cone3.rotation.y = THREE.MathUtils.degToRad(135)

                const cone4 = cone.clone()
                cone4.position.x = 20
                cone4.rotation.y = THREE.MathUtils.degToRad(45)

                const coneSolution = cone.clone()
                coneSolution.position.x = 0
                coneSolution.position.y = 0
                coneSolution.position.z = 0

                const coneSolution1 = coneSolution.clone()
                coneSolution1.rotation.y = THREE.MathUtils.degToRad(45)

                const coneSolution2 = coneSolution.clone()
                coneSolution1.rotation.y = THREE.MathUtils.degToRad(90)

                const coneSolution3 = coneSolution.clone()
                coneSolution1.rotation.y = THREE.MathUtils.degToRad(135)

                const coneSolution4 = coneSolution.clone()
                coneSolution1.rotation.y = THREE.MathUtils.degToRad(180)

                const coneSolution5 = coneSolution.clone()
                coneSolution1.rotation.y = THREE.MathUtils.degToRad(225)

                this.scene[0].add(cone1)
                this.scene[0].add(cone2)
                this.scene[0].add(cone3)
                this.scene[0].add(cone4)

                this.scene[1].add(coneSolution1)
                this.scene[2].add(coneSolution2)
                this.scene[3].add(coneSolution3)
                this.scene[4].add(coneSolution4)
                this.scene[5].add(coneSolution5)

                const fontLoader = new THREE.FontLoader()
                const font = fontLoader.parse(helvetiker.default)
                const questionMarkGeometry = new THREE.TextGeometry('?', {
                    font: font,
                    size: 8,
                    height: 3,
                })
                const questionMarkMaterial = new THREE.MeshBasicMaterial({
                    color: red,
                })
                const questionMark = new THREE.Mesh(questionMarkGeometry, questionMarkMaterial)
                questionMark.position.x = 37.5
                questionMark.position.y = -5

                this.scene[0].add(questionMark)
            } else {
                console.error('Unknown matrix test type', this.parameters.type)
            }

            this.camera[0].position.z = 50
            this.camera[0].position.x = 30
            this.camera[0].position.y = 20
            this.camera[0].lookAt(5, 0, 0)

            for (let i = 1; i <= 5; i++) {
                this.camera[i].position.z = 10
                this.camera[i].position.x = 10
                this.camera[i].position.y = 20
                this.camera[i].lookAt(0, 0, 0)
            }

            this.animate()

            // setTimeout(this.startPause, this.parameters.timeShow)
        },
        animate () {
            if (!this.pause) {
                requestAnimationFrame(this.animate)
            }

            const rendererCount = this.renderer.length
            for (let i = 0; i < rendererCount; i++) {
                this.renderer[i].render(this.scene[i], this.camera[i])
            }
        },
        startPause () {
            // TODO
            this.analytics.push([])

            this.pause = true

            setTimeout(this.endPause, this.parameters.timePause)
        },
        markSolution (i) {
            this.$el.querySelectorAll('.canvas-solution-wrapper canvas').forEach((canvas) => {
                canvas.classList.remove('border-green-500')
            })
            this.$el.querySelectorAll('.canvas-solution-wrapper canvas')[i].classList.add('border-green-500')
        },
    },
}
</script>
