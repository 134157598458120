<template>
    <div class="w-full mx-auto">
        <div class="bg-white text-gray-900 shadow-md rounded px-8 pt-6 pb-8 overflow-y-scroll" style="height: 80vh">
            <div v-html="help" class="mb-8 text-lg"></div>

            <div class="mb-8">
                <a v-on:click="close" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline uppercase w-full cursor-pointer" v-text="$t('next')"></a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Introduction',
    props: [
        'help'
    ],
    methods: {
        close () {
            this.$emit('accepted')
        }
    }
}
</script>
