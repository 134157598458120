import Vue from 'vue'
import VueI18n from 'vue-i18n'

import de from './i18n/de.json'
import en from './i18n/en.json'

const messages = {
    de,
    en,
}

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: navigator.language,
    fallbackLocale: 'de',
    messages,
})

export default i18n
