<template>
    <div class="h-full w-full flex flex-col justify-center relative">
        <div class="text-center text-5xl">
            <template v-if="pause">
            </template>
            <template v-else>
                {{ currentChar }}
            </template>
        </div>

        <div class="absolute bg-blue-500 hover:bg-blue-700 text-white font-bold p-4 rounded-full w-24 h-24 text-center cursor-pointer border-2 border-transparent" :class="{ 'border-white': buttonPushed }" v-on:click="buttonPush" style="bottom: 50px; right: 50px;"></div>
    </div>
</template>

<script>
import moment from 'moment'
import { Howl } from 'howler'

export default {
    name: 'NBackTest',
    data () {
        return {
            series: [],
            alphabet: [],
            index: -1,
            pause: true,
            analytics: {
                series: [],
                clicks: []
            },
            timer: 0,
            clickSound: null,
            buttonPushed: false
        }
    },
    props: {
        parameters: Object
    },
    mounted () {
        this.timer = moment()
        this.clickSound = new Howl({
            src: [
                'sounds/click.mp3',
                'sounds/click.wav'
            ]
        })
        this.alphabet = this.parameters.alphabet.split('')

        if (this.parameters.type === 0) {
            // Fill series with random chars from alphabet
            for (let i = 0; i < this.parameters.count; i++) {
                this.series.push(this.alphabet[Math.floor(Math.random() * this.alphabet.length)])
            }

            // Insert target into series
            for (let i = 1; i <= this.parameters.countTarget; i++) {
                this.series[this.getRandomInt(0, this.series.length)] = this.parameters.target
            }
        } else {
            let chunks = this.parameters.type + 1
            if (chunks % 2 === 0) {
                chunks++
            }

            const dividedAlphabet = this.chunkify(this.alphabet, chunks)
            let i = 0
            while (this.series.length < this.parameters.count) {
                this.series.push(dividedAlphabet[i % chunks][Math.floor(Math.random() * dividedAlphabet[i % chunks].length)])
                i += 2
            }

            const TARGET_FACTOR = (this.parameters.count - this.parameters.type) / this.parameters.countTarget

            // Insert target into series
            for (let i = 1; i <= this.parameters.countTarget; i++) {
                this.series[Math.round(i * TARGET_FACTOR) - this.parameters.type] = this.series[Math.round(i * TARGET_FACTOR)]
            }
        }

        this.analytics.series = this.series

        this.startPause()

        console.debug('Starting nback test')
    },
    methods: {
        /**
         * The maximum is exclusive and the minimum is inclusive
         *
         * @param min
         * @param max
         * @returns {number}
         */
        getRandomInt (min, max) {
            min = Math.ceil(min)
            max = Math.floor(max)
            return Math.floor(Math.random() * (max - min)) + min
        },
        endPause () {
            if (this.index < this.series.length) {
                this.pause = false
                this.timer = moment()

                setTimeout(this.startPause, this.parameters.timeShow)
            } else {
                this.$emit('finished', this.analytics)
            }
        },
        startPause () {
            this.pause = true
            this.timer = moment()
            this.index++

            setTimeout(this.endPause, this.parameters.timePause)
        },
        buttonPush () {
            const that = this

            this.clickSound.play()

            this.buttonPushed = true
            setTimeout(function () {
                that.buttonPushed = false
            }, 500)

            const diff = moment.duration(moment().diff(this.timer))

            this.analytics.clicks.push({
                time: moment().toISOString(),
                timeRelative: diff.asMilliseconds(),
                success: this.success(),
                char: this.currentChar,
                pause: this.pause,
                index: this.index
            })
        },
        success () {
            if (this.parameters.type === 0) {
                return this.currentChar === this.parameters.target
            }

            return this.currentChar === this.series[this.index - this.parameters.type]
        },
        chunkify (a, n) {
            if (n < 2) {
                return [a]
            }

            const len = a.length
            const out = []
            let i = 0
            let size

            if (len % n === 0) {
                size = Math.floor(len / n)
                while (i < len) {
                    out.push(a.slice(i, i += size))
                }
            } else {
                while (i < len) {
                    size = Math.ceil((len - i) / n--)
                    out.push(a.slice(i, i += size))
                }
            }

            return out
        }
    },
    computed: {
        currentChar () {
            return this.series[this.index]
        }
    }
}
</script>
