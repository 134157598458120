<template>
    <div>
        <template v-if="this.$i18n.locale.startsWith('de')">
            <h1>Einverständniserklärung zur Datenerhebung und Datenverarbeitung von personenbezogenen Daten</h1>

            <h2>Forschungsprojekt: Kognitionstest</h2>

            <p>
                Titel des Projektes: Test zur Erfassung der kognitiven Leistungsfähigkeit
            </p>

            <p>
                Durchführende Institution: Bergische Universität Wuppertal
            </p>

            <p>
                Ansprechpartner: Elvira Radaca, Wissenschaftliche Mitarbeiterin am Lehrstuhl für Arbeits-,
                Organisations-
                und
                Wirtschaftspsychologie an der Bergischen Universität Wuppertal, Gaußstraße 20 in 42119 Wuppertal
            </p>

            <p>
                Liebe Teilnehmer*innen,
            </p>

            <p>
                im Rahmen unserer Forschung erheben wir verschiedene Daten zur kognitiven Leistungsfähigkeit. Mit Hilfe
                des
                Kognitionstest können wir verschiedene Teilbereiche (wie zum Beispiel Aufmerksamkeit oder Gedächtnis)
                objektiviert erfassen. Die Daten des Kognitionstest werden mit Fragebogendaten kombiniert, um Aussagen
                über
                Unterschiede zwischen Personen, Veränderungen über die Zeit, dynamische Veränderungsmuster und
                Einflussfaktoren
                dieser Muster zu treffen.
            </p>

            <h2>Datenerhebung und Verarbeitung</h2>
            <p>
                Während des Kognitionstest werden Ihnen zunächst die einzelnen Aufgaben erklärt. Während der Bearbeitung
                werden
                sowohl Ihre Antworten als auch Ihre Reaktionszeiten erfasst.
                Um die Daten des Kognitionstests mit anderen Befragungen kombinieren zu können, ist eine
                personenbezogene
                Zuweisung der Daten notwendig. Daher bitten wir Sie zu Beginn einen individuellen Code zu erstellen, der
                keine
                Rückschlüsse auf die eigene Person zulässt. Dieser Code ermöglicht uns lediglich, verschiedene Messungen
                einer
                Person zuzuordnen. Daher versichern wir, dass die Datenerhebung sowie Datenverarbeitung in
                anonymisierter
                Form
                stattfinden.
            </p>

            <h2>Datenaufbewahrung und Datenspeicherung</h2>
            <p>
                Die Liste mit den Codes wird zudem am Lehrstuhl für Arbeits-, Organisations- und Wirtschaftspsychologie
                der
                Bergischen Universität Wuppertal in gesicherter und geschützter Form aufbewahrt. Ihre Daten werden nicht
                an
                Dritte weitergegeben.
            </p>

            <h2>Wissenschaftliche Auswertung und Veröffentlichung der Ergebnisse</h2>
            <p>
                Ihre Daten werden zu wissenschaftlichen Zwecken erfasst und in anonymisierter Form weiterverarbeitet und
                ausgewertet. Die Veröffentlichung von Ergebnissen in einschlägigen wissenschaftlichen Fachzeitschriften
                und/
                oder Fachkonferenzen erfolgt ausschließlich in anonymer Form und lässt keinen Rückschluss auf Ihre
                Person zu.
            </p>

            <h2>Freiwilligkeit der Teilnahme und Widerrufsrecht</h2>
            <p>
                Ihre Teilnahme an diesem Kognitionstest ist freiwillig. Lehnen Sie die Teilnahme ab oder widerrufen oder
                beschränken Sie Ihre Einwilligung, entstehen Ihnen hieraus keine Nachteile.
                Sie haben zu jeder Zeit das Recht, die datenschutzrechtliche Einwilligung rückgängig zu machen, d.h. zu
                widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung
                bis zum
                Widerruf erfolgten Verarbeitung nicht berührt. Richten Sie hierzu bitte den Widerruf an den
                Verantwortlichen.
            </p>

            <h2>Kontaktdaten des Verantwortlichen</h2>
            <p>
                Elvira Radaca<br>
                Gaußstraße 20<br>
                42119 Wuppertal<br>
                Gebäude S, Raum 12.24<br>
                Tel.: +49 202 439-3945<br>
                Mail: <a href="mailto:radaca@wiwi.uni-wuppertal.de">radaca@wiwi.uni-wuppertal.de</a>
            </p>

            <h2>Hinweise auf Rechte</h2>
            <p>Die für Sie geltenden Rechte sind dem Art. 13 II b der Datenschutzverordnung zu entnehmen.</p>
        </template>

        <template v-else>
            <h1>Data protection regulation</h1>

            <h2>Declaration of consent to data collection and data processing of personal data</h2>

            <p>Research Project: Cognitive Ability Test</p>

            <p>Project title: Test to assess cognitive performance</p>

            <p>Executing Institution: University of Wuppertal</p>

            <p>Contact: Elvira Radaca, Research Assistant at the Chair of Work, Organizational and Business Psychology
                at the Bergische Universität Wuppertal, Gaußstraße 20 in 42119 Wuppertal</p>

            <p>Dear participants,</p>

            <p>in the context of our research we collect various data on cognitive performance. With the help of the
                cognitive ability test, we can objectively assess various sub-areas (such as attention or memory). The
                data
                from the cognitive ability test can be combined with questionnaires in order to investigate differences
                between people, changes over time, dynamic patterns of change and factors influencing these
                patterns.</p>

            <h2>Data collection and processing</h2>
            <p>During the cognitive ability test, you will first have each individual tasks explained to you. During the
                test, both your answers and your reaction times are recorded. In order to be able to combine the data
                from
                the cognition test with other surveys, it is necessary to assign the data to a specific person.
                Therefore,
                we ask you to create an individual code at the beginning, which does not allow any conclusions about
                your
                own person. This code only allows us to assign different measurements to one person. Therefore we assure
                that the data collection as well as data processing is carried out in an anonymous form.</p>

            <h2>Data retention and data storage</h2>
            <p>The list of codes is also stored in a secure and protected form at the Chair of Work, Organizational and
                Business Psychology at the University of Wuppertal. Your data will only be shared in anonymous form with
                other partners of the DINNOS project consortium.</p>

            <h2>Scientific evaluation and publication of the results</h2>
            <p>Your data will be collected for scientific purposes and further processed and evaluated in anonymised
                form.
                The publication of results in relevant scientific journals and/or specialist conferences is exclusively
                in
                anonymous form and does not allow any conclusion to be drawn about your person.</p>

            <h2>Voluntary participation and right of withdrawal</h2>
            <p>Your participation in this cognition test is voluntary. If you refuse to participate or withdraw or limit
                your consent, you will not suffer any disadvantages as a result. You have the right at any time to
                revoke
                your consent under data protection law, i.e. to withdraw it. Revocation of your consent does not affect
                the
                lawfulness of the processing that has taken place on the basis of your consent until revocation. Please
                address the revocation to the person responsible.</p>

            <h2>Contact details of the data controller</h2>
            <p>Elvira Radaca<br>
                Gaussstraße 20<br>
                42119 Wuppertal<br>
                Building S, room 12.24<br>
                phone: +49 202 439-3945<br>
                Mail: <a href="mailto:radaca@wiwi.uni-wuppertal.de">radaca@wiwi.uni-wuppertal.de</a>
            </p>
        </template>
    </div>
</template>

<script>
export default {
    name: 'Privacy',
}
</script>

<style>
h2 {
    @apply font-bold text-lg;
}

a {
    @apply underline;
}
</style>
