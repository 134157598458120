<template>
    <div id="app" class="min-h-screen m-0 p-12 bg-gray-900 text-gray-100 relative">
        <router-view></router-view>

        <div class="absolute bottom-0 left-0 p-4 flex text-gray-600">
            <router-link :to="{ name: 'privacy' }" class="underline" v-text="$t('main.privacy')"></router-link>

            <template v-if="$store.state.settings.debugMode">
                &nbsp;|&nbsp;
                <router-link :to="{ name: 'main' }" class="underline">Abbrechen</router-link>
                <template v-if="user">
                    &nbsp;|&nbsp;
                    {{ user }}
                </template>
                &nbsp;|&nbsp;
                <a @click="skipTest" class="cursor-pointer underline">Überspringen</a>
            </template>
        </div>

        <div class="absolute bottom-0 right-0 p-4 flex">
            <a class="mr-4" href="#" @click="changeLanguage($event, 'en')" title="Switch language to: English">
                <FlagEN class="h-4 w-auto"></FlagEN>
            </a>
            <a href="#" @click="changeLanguage($event, 'de')" title="Ändere Sprache zu: Deutsch">
                <FlagDE class="h-4 w-auto"></FlagDE>
            </a>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/EventBus'

import FlagEN from 'svg-country-flags/svg/gb.svg?inline'
import FlagDE from 'svg-country-flags/svg/de.svg?inline'

export default {
    name: 'App',
    components: {
        FlagEN,
        FlagDE,
    },
    methods: {
        changeLanguage (event, language) {
            event.preventDefault()

            this.$i18n.locale = language

            return false
        },
        skipTest (event) {
            event.preventDefault()

            EventBus.$emit('skipTest')

            return false
        },
    },
}
</script>
