<template>
    <div class="w-full max-w-md mx-auto">
        <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 text-lg" v-on:submit.prevent>
            <p class="mb-2 alert alert-warning md:hidden" v-html="$t('registration.headline.small-device')"></p>
            <code class="block w-full p-2 mb-2 md:hidden" v-text="currentUrl"></code>
            <a class="block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline uppercase w-full cursor-pointer mb-4 md:hidden"
               v-text="$t('registration.headline.copy')" v-on:click="copyClipboard"></a>

            <template v-if="validationStage === false">
                <p class="mb-4 alert alert-info" v-html="$t('registration.headline.standard')"></p>
            </template>
            <template v-else>
                <p class="mb-4 alert alert-info" v-html="$t('registration.headline.validation')"></p>
            </template>

            <ul v-if="errors.length > 0" class="my-4 list-disc">
                <li v-for="error in errors" class="text-red-900" :key="error" v-text="error"></li>
            </ul>

            <template v-if="registrationType === 0">
                <div class="mb-4">
                    <label class="block text-gray-700 text-base font-bold mb-2" for="surname"
                           v-text="$t('registration.type0.surname')"></label>
                    <input v-model.trim="user.surname"
                           class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                           id="surname" type="text" placeholder="Max" required>
                </div>
                <div class="mb-4">
                    <label class="block text-gray-700 text-base font-bold mb-2" for="lastname"
                           v-text="$t('registration.type0.lastname')"></label>
                    <input v-model.trim="user.lastname"
                           class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                           id="lastname" type="text" placeholder="Mustermann" required>
                </div>
                <div class="mb-4">
                    <label class="block text-gray-700 text-base font-bold mb-2" for="birthday"
                           v-text="$t('registration.type0.birthday')">
                        Geburtsdatum
                    </label>
                    <input v-model.trim="user.birthday"
                           class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                           id="birthday" type="date" placeholder="17.04.1959" required>
                </div>
                <div class="mb-8">
                    <label class="block text-gray-700 text-base font-bold mb-2" for="sex"
                           v-text="$t('registration.type0.sex.label')">
                        Geschlecht
                    </label>

                    <select v-model="user.sex"
                            class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="sex" required>
                        <option disabled selected v-text="$t('registration.type0.sex.choose')"></option>
                        <option value="m" v-text="$t('registration.type0.sex.m')"></option>
                        <option value="f" v-text="$t('registration.type0.sex.f')"></option>
                        <option value="d" v-text="$t('registration.type0.sex.d')"></option>
                    </select>
                </div>
            </template>

            <template v-else-if="registrationType === 1">
                <div class="mb-4">
                    <label class="block text-gray-700 text-base font-bold mb-2" for="birthday"
                           v-text="$t('registration.type1.birthday')"></label>
                    <input v-model.trim="user.birthday"
                           class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                           id="birthday" type="date" placeholder="17.04.1959" required>
                </div>
                <div class="mb-4">
                    <label class="block text-gray-700 text-base font-bold mb-2" for="birthplace"
                           v-text="$t('registration.type1.birthplace')"></label>
                    <input v-model.trim="user.birthplace"
                           class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                           id="birthplace" type="text" placeholder="Wu" required>
                </div>
                <div class="mb-4">
                    <label class="block text-gray-700 text-base font-bold mb-2" for="name_mother"
                           v-text="$t('registration.type1.name_mother')"></label>
                    <input v-model.trim="user.name_mother"
                           class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                           id="name_mother" type="text" placeholder="Sa" required>
                </div>
                <div class="mb-4">
                    <label class="block text-gray-700 text-base font-bold mb-2" for="name_father"
                           v-text="$t('registration.type1.name_father')"></label>
                    <input v-model.trim="user.name_father"
                           class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                           id="name_father" type="text" placeholder="er" required>
                </div>
            </template>

            <template v-if="validationStage === true || skipValidation">
                <div class="mb-4">
                    <label class="text-gray-700 text-base font-bold">
                        <input type="checkbox" v-model="user.privacy" name="privacy" required>
                        <span class="pl-2 text-gray-700" v-html="$t('registration.privacy')"></span>
                    </label>
                </div>
            </template>

            <div class="mb-4">
                <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline uppercase w-full cursor-pointer"
                    type="button"
                    @click="validate"
                    v-if="validationStage === false && !skipValidation" v-text="$t('registration.next')"></button>

                <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline uppercase w-full cursor-pointer"
                    type="button"
                    @click="submit"
                    v-if="validationStage || skipValidation" v-text="$t('registration.save_next')"></button>

                <button
                    class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline uppercase w-full cursor-pointer mt-2"
                    v-if="validationStage"
                    @click="validateBack" v-text="$t('registration.back')"></button>
            </div>
            <div class="text-center">
                <router-link :to="{ name: 'main'} " class="text-gray-500"
                             v-text="$t('registration.abort')"></router-link>
            </div>

            <a
                class="text-center text-gray-500 cursor-pointer block mt-2"
                v-if="typeof $store !== 'undefined' && $store.state.settings.debugMode"
                @click="skipWithDefaultValues" v-text="$t('registration.skip')"></a>
        </form>
    </div>
</template>

<script>
function fallbackCopyTextToClipboard (text) {
    const textArea = document.createElement('textarea')
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
        const successful = document.execCommand('copy')
        const msg = successful ? 'successful' : 'unsuccessful'
        console.log('Fallback: Copying text command was ' + msg)
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err)
    }

    document.body.removeChild(textArea)
}

function copyTextToClipboard (text) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text)
        return
    }
    navigator.clipboard.writeText(text).then(function () {
        console.log('Async: Copying to clipboard was successful!')
    }, function (err) {
        console.error('Async: Could not copy text: ', err)
    })
}

export default {
    name: 'UserRegistration',
    props: {
        registrationType: {
            type: Number,
            default: 0,
            required: false,
        },
        skipValidation: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data () {
        return {
            validationStage: false,
            errors: [],
            user: {
                surname: '',
                lastname: '',
                name_mother: '',
                name_father: '',
                birthday: '',
                birthplace: '',
                sex: '',
                privacy: false,
            },
            validated: {},
        }
    },
    methods: {
        validate () {
            this.errors = []

            if (!this.$el.querySelector('form').checkValidity()) {
                this.errors.push(this.$t('registration.form_error'))
                return
            }

            this.validated = JSON.parse(JSON.stringify(this.user))

            this.clearUser()

            this.validationStage = true
        },
        validateBack () {
            this.clearUser()

            this.validationStage = false
        },
        clearUser () {
            this.user.surname = ''
            this.user.lastname = ''
            this.user.name_mother = ''
            this.user.name_father = ''
            this.user.birthday = ''
            this.user.birthplace = ''
            this.user.sex = ''
        },
        submit () {
            let properties
            if (this.registrationType === 0) {
                properties = [
                    {
                        key: 'surname',
                        name: 'Vorname',
                    },
                    {
                        key: 'lastname',
                        name: 'Lastname',
                    },
                    {
                        key: 'birthday',
                        name: 'Geburtstag',
                    },
                    {
                        key: 'sex',
                        name: 'Geschlecht',
                    },
                    {
                        key: 'privacy',
                        name: 'Datenschutzerklärung',
                    },
                ]
            } else {
                properties = [
                    {
                        key: 'birthday',
                        name: 'Geburtstag',
                    },
                    {
                        key: 'birthplace',
                        name: 'Geburtsort',
                    },
                    {
                        key: 'name_mother',
                        name: 'Vorname Mutter',
                    },
                    {
                        key: 'name_father',
                        name: 'Vorname Vater',
                    },
                    {
                        key: 'privacy',
                        name: 'Datenschutzerklärung',
                    },
                ]
            }

            this.errors = []
            for (let i = 0; i < properties.length; i++) {
                const property = properties[i]

                if (property.key === 'privacy') {
                    if (!this.user[property.key]) {
                        this.errors.push(this.$t('registration.privacy_error'))
                    }
                } else if (!this.skipValidation) {
                    if (this.user[property.key].toUpperCase() !== this.validated[property.key].toUpperCase()) {
                        this.errors.push(this.$t('registration.validation_error') + property.name)
                    }
                }
            }

            if (this.errors.length === 0) {
                this.$emit('registered', this.user)
            }
        },
        skipWithDefaultValues () {
            let user
            if (this.registrationType === 0) {
                user = {
                    surname: 'Tom',
                    lastname: 'Test',
                    birthday: '1988-05-13',
                    sex: 'm',
                    privacy: true,
                }
            } else {
                user = {
                    birthday: '1988-05-13',
                    birthplace: 'Wu',
                    name_mother: 'Sa',
                    name_father: 'er',
                    privacy: true,
                }
            }

            this.$emit('registered', user)
        },
        copyClipboard () {
            copyTextToClipboard(window.location.href)
            alert(this.$t('registration.headline.copied'))
        },
    },
    computed: {
        currentUrl () {
            return window.location.href
        },
    },
}
</script>
