import * as Sentry from '@sentry/browser'

if (process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_DSN,
        environment: process.env.NODE_ENV
    })
} else {
    console.warn('No sentry dsn provided.')
}
