<template>
    <div>
        <h1 class="text-center mb-16">{{ title }}</h1>

        <div class="w-full md:w-1/2 mx-auto text-center text-lg">
            <div class="flex mb-8">
                <div class="w-full">
                    <div v-if="error" class="alert alert-error text-left mb-4">
                        {{ error }}
                    </div>

                    <select v-if="templateOptions.length > 0" v-model="templateSelected" class="block w-full bg-grey-lighter border border-grey-lighter text-gray-900 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey">
                        <option value="" disabled>Test auswählen</option>
                        <option v-for="option in templateOptions" v-bind:key="option._id" v-bind:value="option._id">
                            {{ option.name }}
                        </option>
                    </select>
                    <div v-else class="alert alert-error text-left mb-4">
                        Keine Tests gefunden!
                    </div>
                </div>
            </div>

            <template v-if="updateExists">
                <button @click="refreshApp" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded uppercase cursor-pointer">
                    Neue Version verfügbar! Klicken zum aktualisieren.
                </button>
            </template>
            <template v-else>
                <div class="flex mb-4">
                    <div class="w-full md:w-1/2 h-12">
                        <router-link :to="{ name: 'settings' }" class="block py-8 bg-gray-100 text-gray-900 no-underline hover:text-gray-600">
                            <i class="far fa-cogs fa-4x block mb-6" aria-hidden="true"></i>
                            <p class="uppercase font-bold mb-0" v-text="$t('main.options')"></p>
                        </router-link>
                    </div>
                    <div class="w-full md:w-1/2 md:pl-8 h-12">
                        <a v-on:click="start()" class="block py-8 bg-gray-100 text-gray-900 no-underline hover:text-gray-600 cursor-pointer">
                            <i class="far fa-play fa-4x block mb-6" aria-hidden="true"></i>
                            <p class="uppercase font-bold mb-0" v-text="$t('main.start')"></p>
                        </a>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Main',
    data () {
        return {
            title: 'Arbeits-, Organisations- und Wirtschaftspsychologie',
            error: null,
            templateSelected: '',
            refreshing: false,
            registration: null,
            updateExists: false,
        }
    },
    mounted () {
        this.$store.dispatch('templates/loadAll')
    },
    created () {
        document.addEventListener(
            'swUpdated',
            this.showRefreshUI,
            {
                once: true,
            },
        )

        if (window.isSecureContext) {
            navigator.serviceWorker.addEventListener(
                'controllerchange', () => {
                    if (this.refreshing) {
                        return
                    }
                    this.refreshing = true
                    window.location.reload()
                },
            )
        } else {
            console.warn('No navigator.serviceWorker object defined! See https://stackoverflow.com/q/52299246/1100089 for further information.')
        }
    },
    computed: {
        templateOptions () {
            return this.$store.state.templates.templates.filter(function (template) {
                return template.enabled
            })
        },
    },
    methods: {
        start () {
            if (this.templateSelected) {
                this.$router.push({
                    name: 'test',
                    params: {
                        id: this.templateSelected,
                    },
                })
            } else {
                this.error = 'Bitte wählen Sie zunächst einen Test aus.'
            }
        },
        showRefreshUI (e) {
            this.registration = e.detail
            this.updateExists = true
        },
        refreshApp () {
            this.updateExists = false

            if (!this.registration || !this.registration.waiting) {
                return
            }
            this.registration.waiting.postMessage('skipWaiting')
        },
    },
}
</script>
