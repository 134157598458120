<template>
    <div>
        <h1>Einstellungen</h1>

        <div class="mb-2">
            Version: 1.1.0
        </div>

        <div class="mb-2">
            <input type="checkbox" v-model="debugMode" id="debug" name="debug">&nbsp;
            <label for="debug" class="leading-relaxed">Debug Modus</label>
        </div>

        <div class="mb-2">
            Verbindung zur Test-Datenbank:
            <template v-if="dbTemplatesActive">
                <i class="text-green-500 fa fa-circle" aria-hidden="true"></i>
                Online
            </template>
            <template v-else>
                <i class="text-red-500 fa fa-circle" aria-hidden="true"></i>
                Offline oder bereits Synchronisiert
            </template>
        </div>

        <div>
            Verbindung zur Ergebnis-Datenbank:
            <template v-if="dbResultsActive">
                <i class="text-green-500 fa fa-circle" aria-hidden="true"></i>
                Online
            </template>
            <template v-else>
                <i class="text-red-500 fa fa-circle" aria-hidden="true"></i>
                Offline oder bereits Synchronisiert
            </template>
        </div>

        <div class="mt-4">
            <router-link :to="{ name: 'main' }" class="underline">
                Zurück
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Config',
    data () {
        return {
            debugMode: false
        }
    },
    props: {
        msg: String
    },
    mounted () {
        this.debugMode = this.$store.state.settings.debugMode
    },
    computed: {
        dbResultsActive () {
            return this.$store.state.settings.dbResultsActive
        },
        dbTemplatesActive () {
            return this.$store.state.settings.dbTemplatesActive
        }
    },
    watch: {
        debugMode () {
            console.debug('Set debug mode to', this.debugMode)
            this.$store.dispatch('settings/setDebugMode', this.debugMode)
        }
    }
}
</script>
