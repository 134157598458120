import Vue from 'vue'
import VueRouter from 'vue-router'

import Main from './components/Main.vue'
import Settings from './components/Settings.vue'
import Privacy from './components/Privacy.vue'
import Test from './components/Test.vue'

Vue.use(VueRouter)

export default new VueRouter({
    routes: [
        {
            path: '/',
            name: 'main',
            component: Main,
        },
        {
            path: '/settings',
            name: 'settings',
            component: Settings,
        },
        {
            path: '/privacy',
            name: 'privacy',
            component: Privacy,
        },
        {
            path: '/test/:id/:lang?',
            name: 'test',
            component: Test,
            props: true,
        },
    ],
    scrollBehavior () {
        return {
            x: 0,
            y: 0,
        }
    },
})
