<template>
    <div class="h-full w-full relative select-none">
        <div id="svg-wrapper" class="absolute inset-0 z-0">

        </div>
        <div v-for="(val, key) in field" v-bind:key="key" class="text-center inline-block" v-bind:id="'field-' + key" style="height: 10%; width: 5%">
            <div class="flex justify-center flex-col h-full">
                <div>
                    <span v-if="val.visible" class="connection-number visible-number relative z-10" v-bind:class="{ 'connection-number-first': val.first }" v-bind:data-number="val.number" v-bind:data-index="key" @click="clickNumber">
                        {{ val.display }}
                    </span>
                    <span v-else>
                        &nbsp;
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    .connection-line {
        stroke: white;
        stroke-width: 2px;
        stroke-linecap: round;
    }

    .connection-line-correct {
        stroke: #48bb78;
    }

    .connection-line-wrong {
        stroke: #f56565;
    }

    .connection-number {
        @apply bg-gray-100 text-gray-900 p-2 font-bold text-xl rounded;
    }

    .connection-number-first {
        @apply bg-green-500;
    }
</style>

<script>
import moment from 'moment'
import * as d3 from 'd3'

export default {
    name: 'ConnectionTest',
    data () {
        return {
            analytics: {
                hits: [],
                start: null,
                fields: []
            },
            field: [],
            hitcount: 1,
            vis: null,
            lastNumber: null,
            lastIndex: null,
            line: null,
            paddingTop: null,
            paddingLeft: null
        }
    },
    props: {
        parameters: Object
    },
    mounted () {
        const FIELD_MAX = 180

        this.field = []

        for (let i = 0; i < FIELD_MAX; i++) {
            this.field[i] = {
                first: i === 0,
                number: i + 1,
                display: this.getDisplay(i + 1),
                visible: i < this.parameters.max,
                hit: i === 0
            }
        }

        for (let i = 1; i <= this.parameters.max; i++) {
            console.log(this.getDisplay(i))
        }

        this.field = this.shuffle(this.field)
        this.analytics.fields = this.field
        this.analytics.start = moment().toISOString()

        this.vis = d3.select(this.$el.querySelector('#svg-wrapper')).append('svg')
            .attr('width', '100%')
            .attr('height', '100%')

        const that = this

        setTimeout(function () {
            that.$emit('finished', that.analytics)
        }, this.parameters.timeShow)

        console.debug('Starting connection test')
    },
    methods: {
        shuffle (a) {
            let j, x, i

            for (i = a.length - 1; i > 0; i--) {
                j = Math.floor(Math.random() * (i + 1))
                x = a[i]
                a[i] = a[j]
                a[j] = x
            }

            return a
        },

        /**
         * Get what char to display for the number.
         *
         * @param {number} number
         * @returns {string|number|*}
         */
        getDisplay (number) {
            const ALPHABET_FULL = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
                .slice(0, Math.round(this.parameters.max / 2))
            let ALPHABET

            if (this.parameters.alpha && this.parameters.alpha === 'desc') {
                ALPHABET = ALPHABET_FULL.reverse()
            } else {
                ALPHABET = ALPHABET_FULL
            }

            if (this.parameters.kind === 'alphanumeric') {
                if (number % 2 === 0) {
                    // Every even second number is a char
                    return ALPHABET[(Math.round(number / 2) % 26) - 1]
                } else {
                    // Every odd second number stays a number
                    if (this.parameters.numeric && this.parameters.numeric === 'desc') {
                        // If numeric is "desc" count numbers down
                        return Math.round((this.parameters.max - number + 1) / 2)
                    } else {
                        return Math.round((number + 1) / 2)
                    }
                }
            }

            return number
        },

        /**
         * Clicked on number.
         *
         * @param {HTMLSpanElement} e
         */
        clickNumber (e) {
            const el = e.target
            const number = parseInt(el.getAttribute('data-number'))
            const rect = el.getBoundingClientRect()

            if (this.paddingLeft === null) {
                this.paddingTop = parseFloat(window.getComputedStyle(this.$el.querySelector('#field-0'), null).getPropertyValue('height').replace('px', '') / 2)
                this.paddingLeft = parseFloat(window.getComputedStyle(this.$el.querySelector('#field-0'), null).getPropertyValue('width').replace('px', '') / 2)
            }

            console.log('Clicked', number)

            if (number === 1) {
                this.lastNumber = number

                this.line = this.vis.append('line')
                    .attr('x1', rect.left - (this.paddingLeft / 2))
                    .attr('y1', rect.top - (this.paddingTop / 2))
                    .attr('x2', rect.left - (this.paddingLeft / 2))
                    .attr('y2', rect.top - (this.paddingTop / 2))
                    .attr('class', 'connection-line')

                return
            }

            if (this.lastNumber !== null) {
                const index = parseInt(el.getAttribute('data-index'))

                if (this.field[index].hit === false) {
                    this.drawLine(number, index, rect)
                }
            }
        },

        drawLine (currentNumber, currentIndex, rect) {
            this.line
                .attr('x2', rect.left - (this.paddingLeft / 2))
                .attr('y2', rect.top - (this.paddingTop / 2))

            const correct = (this.lastNumber + 1 === parseInt(this.field[currentIndex].number))

            if (correct) {
                this.line.attr('class', 'connection-line connection-line-correct')
            } else {
                this.line.attr('class', 'connection-line connection-line-wrong')
            }

            this.line = this.vis.append('line')
                .attr('x1', rect.left - (this.paddingLeft / 2))
                .attr('y1', rect.top - (this.paddingTop / 2))
                .attr('x2', rect.left - (this.paddingLeft / 2))
                .attr('y2', rect.top - (this.paddingTop / 2))
                .attr('class', 'connection-line')

            this.field[currentIndex].hit = true

            this.analytics.hits.push({
                field: this.field[currentIndex],
                time: moment().toISOString()
            })

            this.hitcount++

            if (this.hitcount === this.parameters.max) {
                console.debug('Test finished')

                this.$emit('finished', this.analytics)
                return
            }

            this.lastNumber++
        }
    }
}
</script>
