<template>
    <div class="relative text-lg" style="height: calc(100vh - 6rem);">
        <template v-if="userRegistered">
            <template v-if="currentTest !== null">
                <div v-if="showHelp" class="bg-white text-gray-900 shadow-md rounded px-8 py-4 overflow-y-scroll" style="height: 80vh;">
                    <h1 v-text="$t('test.help.headline')"></h1>
                    <div v-html="currentTest.help"></div>
                    <p class="my-4" style="margin-bottom: 1.5rem!important;">
                        <a v-on:click="showHelp = false" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded uppercase cursor-pointer">
                            <span v-text="$t('test.start')"></span> <i class="far fa-arrow-right" aria-hidden="true"></i>
                        </a>
                    </p>
                </div>
                <template v-else>
                    <template v-if="currentTest.key === 'nback'">
                        <NBackTest v-bind:parameters="currentTest.parameters" v-on:finished="finishedTest"></NBackTest>
                    </template>
                    <template v-else-if="currentTest.key === 'circle'">
                        <CircleTest v-bind:parameters="currentTest.parameters" v-on:finished="finishedTest"></CircleTest>
                    </template>
                    <template v-else-if="currentTest.key === 'attention'">
                        <AttentionTest v-bind:parameters="currentTest.parameters" v-on:finished="finishedTest"></AttentionTest>
                    </template>
                    <template v-else-if="currentTest.key === 'connection'">
                        <ConnectionTest v-bind:parameters="currentTest.parameters" v-on:finished="finishedTest"></ConnectionTest>
                    </template>
                    <template v-else-if="currentTest.key === 'reminder'">
                        <ReminderTest v-bind:parameters="currentTest.parameters" v-on:finished="finishedTest"></ReminderTest>
                    </template>
                    <template v-else-if="currentTest.key === 'reminder-memory'">
                        <ReminderMemoryTest v-bind:parameters="currentTest.parameters" v-on:finished="finishedTest"></ReminderMemoryTest>
                    </template>
                    <template v-else-if="currentTest.key === 'questions'">
                        <QuestionTest v-bind:parameters="currentTest.parameters" v-on:finished="finishedTest"></QuestionTest>
                    </template>
                    <template v-else-if="currentTest.key === 'creativity-drawing'">
                        <DrawingTest v-bind:parameters="currentTest.parameters" v-on:finished="finishedTest"></DrawingTest>
                    </template>
                    <template v-else-if="currentTest.key === 'creativity-words'">
                        <AlternativeWordTest v-bind:parameters="currentTest.parameters" v-on:finished="finishedTest"></AlternativeWordTest>
                    </template>
                    <template v-else-if="currentTest.key === 'matrix'">
                        <MatrixTest v-bind:parameters="currentTest.parameters" v-on:finished="finishedTest"></MatrixTest>
                    </template>
                    <template v-else>
                        <p class="text-red-900 text-sm bg-red-300 p-4 border-l-4 border-red-500">
                            Unbekannter Test {{ currentTest.key }}.
                        </p>
                    </template>
                </template>
            </template>
            <template v-else>
                <template v-if="template.end_text && template.end_text.length > 0">
                    <div v-html="template.end_text"></div>
                </template>
                <template v-else>
                    <template v-if="template.registration_type === 0">
                        <h1 class="text-white">Sie haben alle Tests erfolgreich beendet.</h1>

                        <router-link :to="{ name: 'main' }" class="absolute bottom-0 right-0 bg-blue-500 hover:bg-blue-700 text-white font-bold p-4 rounded-full w-48 h-16 text-center cursor-pointer">
                            Zurück zum Anfang
                        </router-link>
                    </template>
                    <template v-else-if="template.registration_type === 1">
                        <template v-if="$i18n.locale.startsWith('de')">
                            <h1 class="text-white">Vielen Dank für Ihre Teilnahme!</h1>

                            <p>Wir möchten uns ganz herzlich für Ihre Mithilfe bedanken.<br>
                                Ihre Antworten wurden gespeichert, Sie können das Browser-Fenster nun schließen.</p>

                            <p>Bei Fragen oder technischen Schwierigkeiten können Sie sich an <a class="underline" href="mailto:radaca@wiwi.uni-wuppertal.de">Frau Radaca</a> wenden. Ihre Anfrage wird dann zeitnah bearbeitet.</p>
                        </template>
                        <template v-else>
                            <h1 class="text-white">Thank you very much for your participation!</h1>

                            <p>We would like to thank you very much for your help. Your answers have been saved, you can now close the browser window.</p>

                            <p>If you have any questions or technical difficulties, please contact <a href="mailto:Hohnemann@wiwi.uni-wuppertal.de">Mrs. Hohnemann</a>. Your request will then be processed promptly.</p>
                        </template>
                    </template>
                </template>
            </template>
        </template>
        <template v-else>
            <template v-if="introductionShown">
                <UserRegistration v-on:registered="registered" :registration-type="this.template.registration_type" :skip-validation="this.template.skip_validation || false"></UserRegistration>
            </template>
            <template v-else>
                <Introduction v-bind:help="help" v-on:accepted="introductionAccepted"></Introduction>
            </template>
        </template>
    </div>
</template>

<script>
import { EventBus } from '@/EventBus'

import NBackTest from './tests/NBackTest.vue'
import CircleTest from './tests/CircleTest.vue'
import AttentionTest from './tests/AttentionTest.vue'
import ConnectionTest from './tests/ConnectionTest.vue'
import ReminderTest from './tests/ReminderTest.vue'
import ReminderMemoryTest from './tests/ReminderMemoryTest.vue'
import QuestionTest from './tests/QuestionTest.vue'
import DrawingTest from './tests/DrawingTest.vue'
import AlternativeWordTest from './tests/AlternativeWordTest'
import MatrixTest from './tests/MatrixTest'
import Introduction from './Introduction.vue'
import UserRegistration from './UserRegistration.vue'

export default {
    name: 'Test',
    props: {
        id: {
            required: true,
            type: String,
        },
        lang: {
            required: false,
            type: String,
            default: function () {
                return navigator.language
            },
        },
    },
    components: {
        NBackTest: NBackTest,
        CircleTest: CircleTest,
        AttentionTest: AttentionTest,
        ConnectionTest: ConnectionTest,
        ReminderTest: ReminderTest,
        ReminderMemoryTest: ReminderMemoryTest,
        QuestionTest: QuestionTest,
        DrawingTest: DrawingTest,
        AlternativeWordTest: AlternativeWordTest,
        MatrixTest: MatrixTest,
        Introduction: Introduction,
        UserRegistration: UserRegistration,
    },
    data () {
        return {
            user: null,
            showHelp: true,
            introductionShown: false,
            template: null,
            index: 0,
        }
    },
    mounted () {
        const that = this

        if (this.lang) {
            console.debug('Change locale to', this.lang)
            this.$i18n.locale = this.lang
        }

        this.$store.dispatch('templates/load', this.id)
            .then(function () {
                console.debug('Template loaded')

                const template = that.$store.state.templates.templates.filter(function (template) {
                    return template._id.toString() === that.id.toString()
                })

                if (template.length > 0) {
                    that.template = template[0]
                } else {
                    that.template = null
                }

                if (that.currentTest && that.currentTest.help === null) {
                    that.showHelp = false
                }
            })
            .catch(function (err) {
                console.error(err)
            })

        EventBus.$on('skipTest', () => {
            this.finishedTest(null)
        })
    },
    computed: {
        userRegistered () {
            return this.user !== null
        },
        currentTest () {
            if (this.template === null) {
                console.debug('Current template is empty')
                return null
            }

            // Check if tests are completed
            if (this.index === null || this.index >= this.template.tests.length) {
                console.debug('No more tests to show')
                return null
            }

            return this.template.tests[this.index]
        },
        help () {
            if (this.template) {
                return this.template.help
            }

            return ''
        },
    },
    methods: {
        registered (user) {
            const that = this

            console.debug('Register user', user, this.template.registration_type)

            this.$store.dispatch('results/createOrLoad', {
                user: user,
                registrationType: this.template.registration_type,
            })
                .then(function (hash) {
                    that.user = hash
                })
                .catch(function (err) {
                    console.error(err)

                    that.user = null
                })
        },
        finishedTest (analytics) {
            console.debug('Finished test')

            if (analytics === null) {
                console.debug('Skipped test')

                this.showHelp = true
                this.index++

                if (this.currentTest && this.currentTest.help === null) {
                    this.showHelp = false
                }

                return
            }

            const that = this

            analytics.template = this.template._id
            analytics.test = this.index

            this.$store.dispatch('results/append', {
                hash: this.user,
                analytics: analytics,
            })
                .then(function () {
                    that.showHelp = true
                    that.index++

                    if (that.currentTest && that.currentTest.help === null) {
                        that.showHelp = false
                    }
                })
                .catch(function (err) {
                    console.error(err)
                })
        },
        introductionAccepted () {
            this.introductionShown = true
        },
    },
}
</script>
