<template>
    <div class="h-full w-full flex flex-col justify-center relative">
        <template v-if="pause">
            <div class="h-full w-full flex flex-col text-center justify-center">
                <p class="block text-4xl font-bold">
                    {{ currentWord }}
                </p>
            </div>
        </template>
        <template v-else>
            <h3 class="text-2xl text-center font-bold mb-8"><span v-text="$t('test.alternatives.for_word')"></span> {{ currentWord }}</h3>

            <div class="flex -mx-4">
                <div class="w-1/2 px-4">
                    <input v-model="words[0]" type="text" autocomplete="off">
                    <input v-model="words[1]" type="text" autocomplete="off">
                    <input v-model="words[2]" type="text" autocomplete="off">
                    <input v-model="words[3]" type="text" autocomplete="off">
                    <input v-model="words[4]" type="text" autocomplete="off">
                    <input v-model="words[5]" type="text" autocomplete="off">
                    <input v-model="words[6]" type="text" autocomplete="off">
                    <input v-model="words[7]" type="text" autocomplete="off">
                    <input v-model="words[8]" type="text" autocomplete="off">
                    <input v-model="words[9]" type="text" autocomplete="off">
                </div>

                <div class="w-1/2 px-4">
                    <input v-model="words[10]" type="text" autocomplete="off">
                    <input v-model="words[11]" type="text" autocomplete="off">
                    <input v-model="words[12]" type="text" autocomplete="off">
                    <input v-model="words[13]" type="text" autocomplete="off">
                    <input v-model="words[14]" type="text" autocomplete="off">
                    <input v-model="words[15]" type="text" autocomplete="off">
                    <input v-model="words[16]" type="text" autocomplete="off">
                    <input v-model="words[17]" type="text" autocomplete="off">
                    <input v-model="words[18]" type="text" autocomplete="off">
                    <input v-model="words[19]" type="text" autocomplete="off">
                </div>
            </div>
        </template>
    </div>
</template>

<style scoped>
    input {
        @apply block mb-4 w-full text-black px-2 py-1;
    }
</style>

<script>
export default {
    name: 'AlternativeWordTest',
    data () {
        return {
            timer: null,
            pauseTimer: null,
            pauseTimeout: 5 * 1000,
            pause: true,
            round: 0,
            words: [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
            ],
            uniqueWords: {},
            analytics: {
                rounds: [],
                points: 0
            }
        }
    },
    props: {
        parameters: Object
    },
    mounted () {
        console.debug('Starting word alternative test')

        this.pause = true
        this.pauseTimer = window.setTimeout(this.endPause, this.pauseTimeout)
    },
    methods: {
        endPause () {
            this.pause = false

            this.timer = window.setTimeout(this.startRound, this.parameters.timeShow)
        },
        startRound () {
            console.debug('Starting new round...')

            window.clearTimeout(this.timer)

            this.analytics.rounds[this.round] = {
                word: this.currentWord,
                alternatives: this.words
            }

            for (let i = 0; i < this.words.length; i++) {
                if (this.words[i] && this.words[i].trim()) {
                    this.uniqueWords[this.words[i].trim()] = true
                }
            }

            this.analytics.rounds[this.round].alternatives = Array.from(this.words)

            this.round++

            if (this.round === this.parameters.words.length) {
                this.analytics.points = Object.keys(this.uniqueWords).length

                this.$emit('finished', this.analytics)
                return
            }

            for (let i = 0; i < this.words.length; i++) {
                this.words[i] = null
            }

            // Starting pause
            this.pause = true
            this.pauseTimer = window.setTimeout(this.endPause, this.pauseTimeout)
        }
    },
    computed: {
        currentWord () {
            if (this.parameters.words) {
                return this.parameters.words[this.round]
            }

            return null
        }
    }
}
</script>
