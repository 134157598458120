<template>
    <div class="h-full w-full flex flex-col justify-center relative" style="touch-action: none;">
        <div class="flex flex-row justify-between mb-4">
            <div v-show="!pause" class="mr-4">
                <a v-on:click="reverse" class="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 py-2 rounded text-center cursor-pointer">
                    Rückgängig
                </a>
            </div>
            <div v-if="this.analytics.attachments[0]">
                <img alt="Erstes gemaltes Bild" :src="this.analytics.attachments[0]" class="w-48 h-32 inline-block mr-4">
            </div>
            <div v-if="this.analytics.attachments[1]">
                <img alt="Zweites gemaltes Bild" :src="this.analytics.attachments[1]" class="w-48 h-32 inline-block">
            </div>
            <div v-show="!pause" class="font-bold text-2xl ml-auto">
                Zeit: <span v-text="timer"></span>
            </div>
        </div>

        <div class="w-3/4 m-auto canvas-wrapper">
            <canvas v-show="!pause" class="w-full h-full"></canvas>

            <p v-show="pause" class="text-center font-bold text-xl">Das nächste Bild erscheint in wenigen Sekunden.</p>
        </div>
    </div>
</template>

<style>
    .canvas-wrapper {
        height: 75%;
    }
</style>

<script>
import { enableUndo } from 'undo-canvas'

export default {
    name: 'DrawingTest',
    data () {
        return {
            pause: true,
            testTimer: null,
            timer: null,
            analytics: {
                attachments: [
                    null,
                    null,
                    null
                ]
            },
            currentImage: 0,
            canvasWidth: 0,
            canvasHeight: 0,
            flag: false,
            canvas: null,
            ctx: null,
            prevX: 0,
            currX: 0,
            prevY: 0,
            currY: 0,
            dot_flag: false
        }
    },
    props: {
        parameters: Object
    },
    mounted () {
        this.endPause()

        console.debug('Starting drawing test')
    },
    methods: {
        endPause () {
            const that = this

            this.timer = this.parameters.timeShow / 1000

            that.pause = false

            setTimeout(function () {
                that.canvas = that.$el.querySelector('canvas')
                that.ctx = that.canvas.getContext('2d')

                that.resizeCanvas()
                that.drawStartImage()
                that.initImagePainter()

                enableUndo(that.ctx)
            }, 0)

            setTimeout(this.startPause, this.parameters.timeShow)

            if (this.testTimer) {
                clearInterval(this.testTimer)
            }

            this.testTimer = setInterval(function () {
                that.timer--
            }, 1000)
        },

        startPause () {
            this.analytics.attachments[this.currentImage] = this.canvas.toDataURL('image/png')

            this.pause = true

            this.currentImage++

            if (this.currentImage >= 3) {
                this.$emit('finished', this.analytics)
                return
            }

            setTimeout(this.endPause, this.parameters.timePause)
        },

        resizeCanvas () {
            const canvasWrapper = this.canvas.closest('div')

            this.canvasWidth = canvasWrapper.offsetWidth
            this.canvasHeight = canvasWrapper.offsetHeight

            this.canvas.width = this.canvasWidth
            this.canvas.height = this.canvasHeight

            console.log(this.canvas.width, this.canvas.style)
        },

        relToAbsX (x) {
            return Math.round(this.canvasWidth / 100 * x)
        },

        relToAbsY (y) {
            return Math.round(this.canvasHeight / 100 * y)
        },

        degreesToRadians (degrees) {
            return (degrees * Math.PI) / 180
        },

        drawStartImage () {
            console.debug('Draw starting image')

            this.ctx.fillStyle = '#ffffff'
            this.ctx.fillRect(0, 0, this.canvasWidth, this.canvasHeight)

            this.ctx.fillStyle = 'black'
            this.ctx.lineWidth = 2

            if (this.currentImage === 0) {
                console.debug('Drawing image 1')

                if (this.parameters.type && this.parameters.type === 2) {
                    this.ctx.beginPath()
                    this.ctx.moveTo(this.relToAbsX(10), this.relToAbsY(10))
                    this.ctx.lineTo(this.relToAbsX(10), this.relToAbsY(55))
                    this.ctx.stroke()
                    this.ctx.closePath()

                    this.ctx.beginPath()
                    this.ctx.arc(this.relToAbsX(65), this.relToAbsY(40), this.relToAbsX(10), this.degreesToRadians(90), this.degreesToRadians(0), true)
                    this.ctx.stroke()
                    this.ctx.closePath()

                    this.ctx.beginPath()
                    this.ctx.moveTo(this.relToAbsX(70), this.relToAbsY(80))
                    this.ctx.lineTo(this.relToAbsX(85), this.relToAbsY(80))
                    this.ctx.stroke()
                    this.ctx.closePath()
                } else if (this.parameters.type && this.parameters.type === 3) {
                    this.ctx.beginPath()
                    this.ctx.moveTo(this.relToAbsX(90), this.relToAbsY(25))
                    this.ctx.lineTo(this.relToAbsX(55), this.relToAbsY(25))
                    this.ctx.stroke()
                    this.ctx.closePath()

                    this.ctx.beginPath()
                    this.ctx.arc(this.relToAbsX(65), this.relToAbsY(65), this.relToAbsX(10), this.degreesToRadians(180), this.degreesToRadians(90), true)
                    this.ctx.stroke()
                    this.ctx.closePath()

                    this.ctx.beginPath()
                    this.ctx.moveTo(this.relToAbsX(20), this.relToAbsY(85))
                    this.ctx.lineTo(this.relToAbsX(20), this.relToAbsY(65))
                    this.ctx.stroke()
                    this.ctx.closePath()
                } else {
                    this.ctx.beginPath()
                    this.ctx.moveTo(this.relToAbsX(10), this.relToAbsY(75))
                    this.ctx.lineTo(this.relToAbsX(45), this.relToAbsY(75))
                    this.ctx.stroke()
                    this.ctx.closePath()

                    this.ctx.beginPath()
                    this.ctx.arc(this.relToAbsX(35), this.relToAbsY(35), this.relToAbsX(10), 0, this.degreesToRadians(270), true)
                    this.ctx.stroke()
                    this.ctx.closePath()

                    this.ctx.beginPath()
                    this.ctx.moveTo(this.relToAbsX(80), this.relToAbsY(15))
                    this.ctx.lineTo(this.relToAbsX(80), this.relToAbsY(35))
                    this.ctx.stroke()
                    this.ctx.closePath()
                }
            } else if (this.currentImage === 1) {
                console.debug('Drawing image 2')

                if (this.parameters.type && this.parameters.type === 2) {
                    this.ctx.beginPath()
                    this.ctx.moveTo(this.relToAbsX(55), this.relToAbsY(20))
                    this.ctx.lineTo(this.relToAbsX(55), this.relToAbsY(40))
                    this.ctx.stroke()
                    this.ctx.closePath()

                    this.ctx.beginPath()
                    this.ctx.arc(this.relToAbsX(30), this.relToAbsY(70), this.relToAbsX(10), this.degreesToRadians(135), this.degreesToRadians(225))
                    this.ctx.stroke()
                    this.ctx.closePath()

                    this.ctx.beginPath()
                    this.ctx.moveTo(this.relToAbsX(80), this.relToAbsY(80))
                    this.ctx.lineTo(this.relToAbsX(100), this.relToAbsY(80))
                    this.ctx.stroke()
                    this.ctx.closePath()
                } else if (this.parameters.type && this.parameters.type === 3) {
                    this.ctx.beginPath()
                    this.ctx.moveTo(this.relToAbsX(45), this.relToAbsY(60))
                    this.ctx.lineTo(this.relToAbsX(80), this.relToAbsY(60))
                    this.ctx.stroke()
                    this.ctx.closePath()

                    this.ctx.beginPath()
                    this.ctx.arc(this.relToAbsX(30), this.relToAbsY(30), this.relToAbsX(10), this.degreesToRadians(225), this.degreesToRadians(315))
                    this.ctx.stroke()
                    this.ctx.closePath()

                    this.ctx.beginPath()
                    this.ctx.moveTo(this.relToAbsX(20), this.relToAbsY(80))
                    this.ctx.lineTo(this.relToAbsX(20), this.relToAbsY(100))
                    this.ctx.stroke()
                    this.ctx.closePath()
                } else {
                    this.ctx.beginPath()
                    this.ctx.moveTo(this.relToAbsX(20), this.relToAbsY(40))
                    this.ctx.lineTo(this.relToAbsX(55), this.relToAbsY(40))
                    this.ctx.stroke()
                    this.ctx.closePath()

                    this.ctx.beginPath()
                    this.ctx.arc(this.relToAbsX(70), this.relToAbsY(70), this.relToAbsX(10), this.degreesToRadians(45), this.degreesToRadians(135))
                    this.ctx.stroke()
                    this.ctx.closePath()

                    this.ctx.beginPath()
                    this.ctx.moveTo(this.relToAbsX(80), this.relToAbsY(0))
                    this.ctx.lineTo(this.relToAbsX(80), this.relToAbsY(20))
                    this.ctx.stroke()
                    this.ctx.closePath()
                }
            } else if (this.currentImage === 2) {
                console.debug('Drawing image 3')

                if (this.parameters.type && this.parameters.type === 2) {
                    this.ctx.beginPath()
                    this.ctx.moveTo(this.relToAbsX(60), this.relToAbsY(40))
                    this.ctx.lineTo(this.relToAbsX(60), this.relToAbsY(60))
                    this.ctx.stroke()
                    this.ctx.closePath()

                    this.ctx.beginPath()
                    this.ctx.arc(this.relToAbsX(60), this.relToAbsY(40), this.relToAbsX(10), this.degreesToRadians(90), this.degreesToRadians(0), true)
                    this.ctx.stroke()
                    this.ctx.closePath()

                    this.ctx.beginPath()
                    this.ctx.moveTo(this.relToAbsX(5), this.relToAbsY(5))
                    this.ctx.lineTo(this.relToAbsX(30), this.relToAbsY(5))
                    this.ctx.stroke()
                    this.ctx.closePath()
                } else if (this.parameters.type && this.parameters.type === 3) {
                    this.ctx.beginPath()
                    this.ctx.moveTo(this.relToAbsX(40), this.relToAbsY(60))
                    this.ctx.lineTo(this.relToAbsX(60), this.relToAbsY(60))
                    this.ctx.stroke()
                    this.ctx.closePath()

                    this.ctx.beginPath()
                    this.ctx.arc(this.relToAbsX(60), this.relToAbsY(60), this.relToAbsX(10), this.degreesToRadians(180), this.degreesToRadians(90), true)
                    this.ctx.stroke()
                    this.ctx.closePath()

                    this.ctx.beginPath()
                    this.ctx.moveTo(this.relToAbsX(95), this.relToAbsY(5))
                    this.ctx.lineTo(this.relToAbsX(95), this.relToAbsY(30))
                    this.ctx.stroke()
                    this.ctx.closePath()
                } else {
                    this.ctx.beginPath()
                    this.ctx.moveTo(this.relToAbsX(40), this.relToAbsY(40))
                    this.ctx.lineTo(this.relToAbsX(60), this.relToAbsY(40))
                    this.ctx.stroke()
                    this.ctx.closePath()

                    this.ctx.beginPath()
                    this.ctx.arc(this.relToAbsX(40), this.relToAbsY(40), this.relToAbsX(10), 0, this.degreesToRadians(270), true)
                    this.ctx.stroke()
                    this.ctx.closePath()

                    this.ctx.beginPath()
                    this.ctx.moveTo(this.relToAbsX(5), this.relToAbsY(70))
                    this.ctx.lineTo(this.relToAbsX(5), this.relToAbsY(95))
                    this.ctx.stroke()
                    this.ctx.closePath()
                }
            }
        },

        initImagePainter () {
            console.debug('Init image painter')

            const that = this

            this.canvas.addEventListener('mousemove', function (e) {
                that.paintCanvas('move', e)
            }, false)
            this.canvas.addEventListener('touchmove', function (e) {
                that.paintCanvas('move', e.touches[0])
            })

            this.canvas.addEventListener('mousedown', function (e) {
                that.paintCanvas('down', e)
            }, false)
            this.canvas.addEventListener('touchstart', function (e) {
                that.paintCanvas('down', e.touches[0])
            }, false)

            this.canvas.addEventListener('mouseup', function (e) {
                that.paintCanvas('up', e)
            }, false)
            this.canvas.addEventListener('touchend', function (e) {
                that.paintCanvas('up', e.touches[0])
            }, false)

            this.canvas.addEventListener('mouseout', function (e) {
                that.paintCanvas('out', e)
            }, false)
            this.canvas.addEventListener('touchcancel', function () {
                that.paintCanvas('out', null)
            }, false)
        },

        paintCanvas (res, e) {
            if (res === 'down') {
                this.prevX = this.currX
                this.prevY = this.currY
                this.currX = e.clientX - this.canvas.closest('div').offsetLeft - (3 * 16)
                this.currY = e.clientY - this.canvas.closest('div').offsetTop - (3 * 16)

                this.flag = true
                this.dot_flag = true

                this.ctx.putTag()

                if (this.dot_flag) {
                    this.ctx.beginPath()
                    this.ctx.fillStyle = this.x
                    this.ctx.fillRect(this.currX, this.currY, 2, 2)
                    this.ctx.closePath()

                    this.dot_flag = false
                }
            }

            if (res === 'up' || res === 'out') {
                this.flag = false

                this.ctx.putTag()
            }

            if (res === 'move') {
                if (this.flag) {
                    this.prevX = this.currX
                    this.prevY = this.currY
                    this.currX = e.clientX - this.canvas.closest('div').offsetLeft - (3 * 16)
                    this.currY = e.clientY - this.canvas.closest('div').offsetTop - (3 * 16)

                    this.draw()
                }
            }
        },

        draw () {
            this.ctx.beginPath()
            this.ctx.moveTo(this.prevX, this.prevY)
            this.ctx.lineTo(this.currX, this.currY)
            this.ctx.closePath()
            this.ctx.stroke()
        },

        reverse () {
            console.debug('Reverse last stroke')

            this.ctx.undoTag()
        }
    }
}
</script>
